const Layouts = () => import('@/layouts/index.vue')

export const MemberRouter = {
  path: '/member',
  component: Layouts,
  name: 'member',
  meta: {
    title: '用户管理',
    svgIcon: 'menu',
    alwaysShow: true // 将始终显示根菜单
  },
  children: [
    {
      path: 'members',
      component: () => import('@/views/member/members/index.vue'),
      name: 'MemberList',
      meta: {
        title: '会员'
      }
    },
    {
      path: 'members/edit',
      component: () => import('@/views/member/members/edit/index.vue'),
      name: 'membersEdit',
      meta: {
        title: '会员列表-编辑',
        hidden: true
      }
    },
  ]
}
