const Layouts = () => import('@/layouts/index.vue')

export const ContentRouter = {
  path: '/content',
  component: Layouts,
  name: 'content',
  meta: {
    title: '内容管理',
    svgIcon: 'menu',
    alwaysShow: true // 将始终显示根菜单
  },
  children: [
    {
      path: 'article',
      component: () => import('@/views/content/article/index.vue'),
      name: 'ArticleList',
      meta: {
        title: '文章列表'
      }
    },
    {
      path: 'article/edit',
      component: () => import('@/views/content/article/edit/index.vue'),
      name: 'ArticleEdit',
      meta: {
        title: '文章编辑',
        hidden: true
      }
    },
    {
      path: 'timeline',
      component: () => import('@/views/content/timeline/index.vue'),
      name: 'TimeLineList',
      meta: {
        title: '时间轴'
      }
    },
    {
      path: 'timeline/edit',
      component: () => import('@/views/content/timeline/edit/index.vue'),
      name: 'TimeLineEdit',
      meta: {
        title: '编辑时间轴',
        hidden: true
      }
    },
    {
      path: 'timeline/add',
      component: () => import('@/views/content/timeline/edit/index.vue'),
      name: 'TimeLineAdd',
      meta: {
        title: '添加时间轴',
        hidden: true
      }
    },
  ]
}
