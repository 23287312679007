import {type RouteRecordRaw, createRouter, createWebHashHistory} from 'vue-router'
import {GameRouter} from "@/router/module/game";
import {ContentRouter} from "@/router/module/content";
import {MemberRouter} from "@/router/module/member";
import {VersionRouter} from "@/router/module/version";
// import { history } from './helper'

const Layouts = () => import('@/layouts/index.vue')

/**
 * 常驻路由
 * 除了 redirect/403/404/login 等隐藏页面，其他页面建议设置 Name 属性
 */
export const constantRoutes: RouteRecordRaw[] = [
  {
    path: '/redirect',
    component: Layouts,
    meta: {
      hidden: true
    },
    children: [
      {
        path: '/redirect/:path(.*)',
        component: () => import('@/views/redirect/index.vue')
      }
    ]
  },
  {
    path: '/403',
    component: () => import('@/views/error-page/403.vue'),
    meta: {
      hidden: true
    }
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/404.vue'),
    meta: {
      hidden: true
    },
    alias: '/:pathMatch(.*)*'
  },
  {
    path: '/login',
    component: () => import('@/views/login/index.vue'),
    meta: {
      hidden: true
    }
  },
  {
    path: '/',
    component: Layouts,
    children: [],
    redirect: '/index'
  }
]

/**
 * 动态路由
 * 用来放置有权限 (Roles 属性) 的路由
 * 必须带有 Name 属性
 */
export const asyncRoutes: RouteRecordRaw[] = [
  // TODO: 添加菜单需要先从认证系统里面添加权限后才能添加菜单
  {
    path: '/index',
    component: Layouts,
    redirect: '/index/home',
    name: 'Index',
    meta: {
      title: '首页',
      svgIcon: 'lock',
      alwaysShow: true // 将始终显示根菜单
    },
    children: [
      {
        path: 'home',
        component: () => import('@/views/index/home.vue'),
        name: 'indexHome',
        meta: {
          title: '主页'
        }
      }
    ]
  },
  GameRouter,
  ContentRouter,
  MemberRouter,
  VersionRouter,
  {
    path: '/steam',
    component: Layouts,
    redirect: '/steam/tool_box/list',
    name: 'Steam',
    meta: {
      title: 'Steam宝盒',
      svgIcon: 'link',
      alwaysShow: true // 将始终显示根菜单
    },
    children: [
      {
        path: 'tool_box/list',
        component: () => import('@/views/steam/treasure_bag/list/index.vue'),
        name: 'ToolBoxList',
        meta: {
          title: '百宝袋',
          svgIcon: 'link'
        }
      },
      {
        path: 'advertising_space/list',
        component: () => import('@/views/steam/advertising_space/list/index.vue'),
        name: 'AdvertisingSpaceList',
        meta: {
          title: '广告位',
          svgIcon: 'link'
        }
      }
    ]
  },
  {
    path: '/translate',
    component: Layouts,
    redirect: '/translate/vocabulary/list',
    name: '翻译管理',
    meta: {
      title: '翻译管理',
      svgIcon: 'link',
      alwaysShow: true // 将始终显示根菜单
    },
    children: [
      {
        path: 'vocabulary/list',
        component: () => import('@/views/translate/vocabulary/index.vue'),
        name: 'VocabularyList',
        meta: {
          title: '词库',
          svgIcon: 'link'
        }
      },
      {
        path: 'entry/list',
        component: () => import('@/views/translate/vocabulary/entry/index.vue'),
        name: 'EntryList',
        meta: {
          title: '词条',
          svgIcon: 'link',
          hidden: true
        }
      },
      {
        path: 'support_game_config',
        component: () => import('@/views/translate/vocabulary/support_game_config.vue'),
        name: 'supportGameConfig',
        meta: {
          title: '支持游戏配置',
          svgIcon: 'link'
        }
      }
    ]
  },
  {
    path: '/man_translate',
    component: Layouts,
    redirect: '/man_translate/translate_task/list',
    name: '人工翻译',
    meta: {
      title: '人工翻译',
      svgIcon: 'link',
      alwaysShow: true // 将始终显示根菜单
    },
    children: [
      {
        path: 'translate_task/list',
        component: () => import('@/views/man_translate/task.vue'),
        name: 'TaskList',
        meta: {
          title: '翻译任务',
          svgIcon: 'link'
        }
      },
      {
        path: 'man_vocabulary/list',
        component: () => import('@/views/man_translate/vocabulary.vue'),
        name: 'ManVocabularyList',
        meta: {
          title: '游戏词库',
          svgIcon: 'link',
          hidden: false
        }
      },
      {
        path: 'man_entry/list',
        component: () => import('@/views/man_translate/entry.vue'),
        name: 'ManEntryList',
        meta: {
          title: '游戏词条',
          svgIcon: 'link',
          hidden: true
        }
      }
    ]
  },
  {
    path: '/:pathMatch(.*)*', // Must put the 'ErrorPage' route at the end, 必须将 'ErrorPage' 路由放在最后
    redirect: '/404',
    name: 'ErrorPage',
    meta: {
      hidden: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes: constantRoutes
})

/** 重置路由 */
export function resetRouter() {
  // 注意：所有动态路由路由必须带有 Name 属性，否则可能会不能完全重置干净
  try {
    router.getRoutes().forEach((route) => {
      const {name, meta} = route
      if (name && meta.roles?.length) {
        router.hasRoute(name) && router.removeRoute(name)
      }
    })
  } catch {
    // 强制刷新浏览器也行，只是交互体验不是很好
    window.location.reload()
  }
}

export default router
