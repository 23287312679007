/*
  定义后台接口地址常量，方便统一管理
 */
export const MANAGEMENT_LOGIN = '/api/multi_platform_manage_admin_bff/v1/login'
// export const MANAGEMENT_LOGOUT = '/api/game_store_admin/v1/rbac/logout'
// export const MANAGEMENT_USER = '/api/game_store_admin/v1/rbac/user'
export const MANAGEMENT_MENUS = '/api/multi_platform_manage_admin_bff/v1/user/menus' // '/api/manage_platform/v1/menus'
// export const MANAGEMENT_AUTH = '/api/game_store_admin/v1/rbac/auth/:token'

//接口
export const UPLOAD_FILE = '/api/multi_platform_manage_admin_bff/v1/game/upload-file'
export const UPLOAD_POLICY_TOKEN = '/api/multi_platform_manage_admin_bff/v1/common/oss-upload-token'
export const UPLOAD_PROXY = '/api/multi_platform_manage_admin_bff/v1/common/oss-upload-proxy'
export const GAME_OPTIONS = '/api/multi_platform_manage_admin_bff/v1/game/options'
export const GAME_LIST = '/api/multi_platform_manage_admin_bff/v1/game/list'
export const GAME_DELETE = '/api/multi_platform_manage_admin_bff/v1/game/delete'
export const GAME_DETAIL = '/api/multi_platform_manage_admin_bff/v1/game/detail'
export const GAME_DETAIL_BY_APPID = '/api/multi_platform_manage_admin_bff/v1/game/detail_by_appid'
export const GAME_UPDATE = '/api/multi_platform_manage_admin_bff/v1/game/update'
export const RELATE_GAMES = '/api/multi_platform_manage_admin_bff/v1/game/relate-games'
export const SYNC_GAME = '/api/multi_platform_manage_admin_bff/v1/game/sync'
export const GAME_CHOICES = '/api/multi_platform_manage_admin_bff/v1/game/game-choices'

//文件上传接口
export const FILE_UPLOAD = '/api/multi_platform_manage_admin_bff/v1/game/upload-file'

export const TREASURE_BAG_LIST = '/api/multi_platform_manage_admin_bff/v1/steam_baohe/treasure_bag/page_list'

export const ADD_TREASURE_BAG = '/api/multi_platform_manage_admin_bff/v1/steam_baohe/treasure_bag/add'
export const UPDATE_TREASURE_BAG = '/api/multi_platform_manage_admin_bff/v1/steam_baohe/treasure_bag/update'

export const DELETE_TREASURE_BAG = '/api/multi_platform_manage_admin_bff/v1/steam_baohe/treasure_bag/delete'

export const SORT_TREASURE_BAG = '/api/multi_platform_manage_admin_bff/v1/steam_baohe/treasure_bag/rearrange'

// Steam宝盒 广告位接口
export const ADVERTISING_LIST = '/api/multi_platform_manage_admin_bff/v1/steam_baohe/advertising/page_list'

export const ADD_ADVERTISING = '/api/multi_platform_manage_admin_bff/v1/steam_baohe/advertising/add'
export const UPDATE_ADVERTISING = '/api/multi_platform_manage_admin_bff/v1/steam_baohe/advertising/update'
export const DELETE_ADVERTISING = '/api/multi_platform_manage_admin_bff/v1/steam_baohe/advertising/delete'
export const SORT_ADVERTISING = '/api/multi_platform_manage_admin_bff/v1/steam_baohe/advertising/rearrange'

// 翻译工具接口
export const VOCABULARY_LIST = '/api/multi_platform_manage_admin_bff/v1/vocabulary'
export const ENTRY_LIST = '/api/multi_platform_manage_admin_bff/v1/entry'
export const SUPPORT_GAME_CONFIG = '/api/multi_platform_manage_admin_bff/v1/support_game_config'
export const SUPPORT_GAME_CONFIG_UPDATE = '/api/multi_platform_manage_admin_bff/v1/support_game_config/update'

// 人工翻译接口
export const TaskList = '/api/multi_platform_manage_admin_bff/v1/translate_task'
export const UploadTaskFile = '/api/multi_platform_manage_admin_bff/v1/translate_file'
export const CreateTask = '/api/multi_platform_manage_admin_bff/v1/translate_task'
export const ExportTask = '/api/multi_platform_manage_admin_bff/v1/translate_task/export'
export const ManVocabularyList = '/api/multi_platform_manage_admin_bff/v1/man_vocabulary'
export const ManEntryList = '/api/multi_platform_manage_admin_bff/v1/man_entry'
export const ManVocabularyChangeLog = '/api/multi_platform_manage_admin_bff/v1/man_vocabulary/change_log'

// 时间轴接口
export const TIME_LINE = '/api/multi_platform_manage_admin_bff/v1/timeline'

// 关键词接口
export const SEARCH_KEYWORD = '/api/multi_platform_manage_admin_bff/v1/keyword'
export const SEARCH_KEYWORD_SYNC = '/api/multi_platform_manage_admin_bff/v1/keyword/sync'

// ueditor接口
export const UEDITOR_SERVER_URL = import.meta.env.VITE_BASE_API + '/api/multi_platform_manage_admin_bff/v1/ueditor'

// 文章接口
export const ARTICLE = "/api/multi_platform_manage_admin_bff/v1/article"
export const ARTICLE_SYNC = "/api/multi_platform_manage_admin_bff/v1/article/sync"
export const ARTICLE_ENUM_LIST = "/api/multi_platform_manage_admin_bff/v1/article/enum_list"
export const ARTICLE_COPY = "/api/multi_platform_manage_admin_bff/v1/article/copy"

// channel接口
export const CHANNEL_SEARCH = "/api/multi_platform_manage_admin_bff/v1/channel/search"

// 标签接口
export const TAG = "/api/multi_platform_manage_admin_bff/v1/tag"
export const TAG_GAME = "/api/multi_platform_manage_admin_bff/v1/tag_game"

// PC游戏下载资源
export const PC_DOWNLOAD = "/api/multi_platform_manage_admin_bff/v1/pc_download"
export const PC_DOWNLOAD_LIST = "/api/multi_platform_manage_admin_bff/v1/pc_downloads"

// 客户端版本
export const CLIENT_VERSION = "/api/multi_platform_manage_admin_bff/v1/version_parameter"

// 客户端版本参数
export const CLIENT_VERSION_PARAMS = "/api/multi_platform_manage_admin_bff/v1/version_parameter"

// 会员接口
export const MEMBER_LIST = "/api/multi_platform_manage_admin_bff/v1/users"
export const MEMBER = "/api/multi_platform_manage_admin_bff/v1/user"

